<template>
  <CardToolbar
    description="Artist's you have searched the most"
    title="Suggestions"
    :show-toolbar-buttons="false"
  >
    <template v-slot:body>
      <el-skeleton v-if="loading" :rows="10" />
      <el-row :gutter="10">
        <el-col
          class="text-center mb-5"
          :xs="24"
          :sm="12"
          :md="8"
          :lg="6"
          v-for="userSearchHistory in userSearchHistories"
          :key="userSearchHistory.artist.uuid"
        >
          <router-link
            to=""
            :disabled="true"
            @click="openArtistProfileDrawer(userSearchHistory.artist.uuid)"
            ><el-avatar
              :size="100"
              :src="userSearchHistory.artist.imageUrl"
            ></el-avatar>
            <h3 class="artist-name truncate">
              {{ userSearchHistory.artist.name.toUpperCase() }}
            </h3></router-link
          >
        </el-col>
      </el-row>
    </template>
  </CardToolbar>
</template>

<script>
import { onMounted, ref } from "vue";

import { getUserSearchHistory } from "@/api/user.api";
import CardToolbar from "@/common/components/CardToolbar";

export default {
  name: "UserSearchHistory",
  components: { CardToolbar },
  setup() {
    const userSearchHistories = ref([]);
    const loading = ref(true);

    onMounted(async () => {
      try {
        const { data } = await getUserSearchHistory();
        userSearchHistories.value = data.data;
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    });
    return { userSearchHistories, loading };
  },
};
</script>

<style scoped></style>
